<template>
  <div class="role-access reset-btn-page">
    <div class="head">
      <h2 class="title">访问授权列表</h2>
    </div>
    <div class="content" v-loading="loading">
      <el-button size="mini" :type="isExpand ? 'plain':'primary'" @click="isExpand = !isExpand">
        <span class="button-text">一键{{isExpand ? '收起':'展开'}}</span>
        <i :class="`el-icon-arrow-${isExpand ? 'up' : 'down'}`"></i></el-button>
      <el-tree :data="nodesData"
               show-checkbox
               node-key="id"
               :props="defaultProps"
               ref="roleTree"
               :default-checked-keys="defaultCheckedKeys"
               :check-strictly="true"
               @check="handleCheck"
      ></el-tree>
    </div>
    <FixedActionBar>
      <el-button size="medium" type="primary" :loading="saveLoading" @click="oncConfirm('roleTree')">提交
      </el-button>
      <el-button size="medium" @click="onCancel">取消</el-button>
    </FixedActionBar>
  </div>
</template>
<script>
  import {roleNodes, roleAuthNodes} from "../api/role";
  import FixedActionBar from "@/base/layout/FixedActionBar";

  export default {
    components: {FixedActionBar},
    data() {
      return {
        loading: false, // 加载中
        // 树配置参数
        defaultProps: {
          children: "_children",
          label: "name",
        },
        nodesData: [], // 请求返回的初始数据
        // 勾选节点的数组，不区分父子节点
        defaultCheckedKeys: [],
        saveLoading: false, // 提交中
        isExpand: false,
      };
    },
    computed: {
      //角色id
      roleId() {
        const id = this.$route.params.id;
        return id ? Number(id) : "";
      },
    },
    watch: {
      isExpand(val) {
        if (val) {
          this.unFoldAll()
        } else {
          this.collapseAll();
        }
      }
    },
    created() {
      //获取节点
      this.getRoleNodes();
    },
    // el-tree组件有变化时给多选树重新赋值
    updated() {
      // 给多选树设置默认值
      this.$refs['roleTree'].setCheckedKeys(this.defaultCheckedKeys)
    },
    methods: {
      // 全部展开
      unFoldAll(li) {
        let self = this;
        if (li && li.length === 0) return;
        // 将没有转换成树的原数据
        let list = li || this.nodesData;
        for (let i = 0; i < list.length; i++) {
          // 将没有转换成树的原数据设置key为... 的展开
          self.$refs.roleTree.store.nodesMap[list[i].id].expanded = true
          this.unFoldAll(list[i]._children || [])
        }
      },
      // 全部折叠
      collapseAll(li) {
        let self = this;
        if (li && li.length === 0) return;
        // 将没有转换成树的原数据
        let list = li || this.nodesData;
        for (let i = 0; i < list.length; i++) {
          self.$refs.roleTree.store.nodesMap[list[i].id].expanded = false
          this.collapseAll(list[i]._children || [])
        }
      },
      handleCheck(currentObj, treeStatus) {
        // 用于：父子节点严格互不关联时，父节点勾选变化时通知子节点同步变化，实现单向关联。
        let selected = treeStatus.checkedKeys.indexOf(currentObj.id) // -1未选中
        // 选中
        if (selected !== -1) {
          // 子节点只要被选中父节点就被选中
          this.selectedParent(currentObj)
          // 统一处理子节点为相同的勾选状态
          this.uniteChildSame(currentObj, true)
        } else {
          // 未选中 处理子节点全部未选中
          if (currentObj._children && currentObj._children.length !== 0) {
            this.uniteChildSame(currentObj, false)
          }
        }
      },
      // 统一处理子节点为相同的勾选状态
      uniteChildSame(treeList, isSelected) {
        this.$refs['roleTree'].setChecked(treeList.id, isSelected)
        if (treeList._children && treeList._children.length) {
          for (let i = 0; i < treeList._children.length; i++) {
            this.uniteChildSame(treeList._children[i], isSelected)
          }
        }
      },
      // 统一处理父节点为选中
      selectedParent(currentObj) {
        let currentNode = this.$refs['roleTree'].getNode(currentObj)
        if (currentNode.parent.key !== undefined) {
          this.$refs['roleTree'].setChecked(currentNode.parent, true)
          this.selectedParent(currentNode.parent)
        }
      },
      // 获取节点
      getRoleNodes() {
        if (!this.roleId) {
          this.$message.warning("没有检测到可授权的角色");
          return;
        }
        this.loading = true;
        roleNodes({id: this.roleId})
          .then((res) => {
            const {data} = res;
            this.nodesData = data.nodes.map(el => ({
              id: el.id,
              name: el.name,
              _children: el._children,
            }));
            this.defaultCheckedKeys = data.checked_ids;
            this.loading = false;
            // console.log(data);
          })
          .catch((err) => {
            this.loading = true;
          });
      },
      //提交
      oncConfirm(formName) {
        if (!this.roleId) {
          this.$message.warning("没有检测到可授权的角色");
          return;
        }
        // 拿到所有的选中项id数组
        const idArr = this.$refs[formName].getCheckedKeys();
        this.defaultCheckedKeys = idArr;
        if (!idArr.length) {
          this.$message.warning("请选择至少一项再提交");
          return;
        }
        //提交的数据
        this.saveLoading = true;
        const postData = {id: this.roleId, node_ids: idArr};
        //提交
        roleAuthNodes(postData)
          .then((res) => {
            this.saveLoading = false;
            this.$message.success(res.msg);
            this.$router.push({name: "AdminRoleList"});
          })
          .catch((err) => {
            this.saveLoading = false;
          });
      },
      //取消
      onCancel() {
        this.$router.go(-1);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .role-access {
    .head {
      margin-bottom: 32px;

      .title {
        font-size: 18px;
        font-weight: normal;
      }
    }

    .content {
      /*min-height: 400px;*/

      .el-button {
        margin-bottom: 30px;
      }

      .button-text {
        margin-right: 5px;
      }
    }
  }
</style>
